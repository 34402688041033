<template>
  <b-sidebar
    id="add-receipt-user-sidebar"
    :visible="isAddNewReceiptSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-receipt-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ receiptLocal.id ? 'Update': 'Add' }} Receipt
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

        <b-row
          v-if="currencies.length > 0"
        >
            <!-- Currency -->
            <b-col md="6">
              <b-form-group
                label="Foreign Currency"
                label-for="currency"
              >
                <v-select
                  v-model="receiptLocal.currency"
                 
                  :options="currencies.map(c=>({ currency: { name: c.currency.name, sign: c.currency.sign }, rate: c.rate }))"
                  label="currency"
                  :close-on-select="true"
                  :clearable="true"
                  input-id="currency"
                  @input="renderAmount"
                >
                  <template #option="{ currency, rate }">
                    <!-- <b-avatar
                      size="sm"
                      :src="avatar"
                    /> -->
                    <!-- <span> {{ memID }}</span> -->
                    <span class="ml-50 align-middle"> {{ currency.sign+' '+currency.name }} - {{ rate }}</span>
                  </template>

                  <template #selected-option="{ currency, rate }">
                    <!-- <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                    /> -->
                    <span class="ml-50 align-middle"> {{ currency.name }} - {{ rate }}</span>
                  </template> 
                </v-select>   
              </b-form-group>
            </b-col>
            <!-- Amount -->
            <b-col md="6">
              <b-form-group
                  label="Foreign Amount"
                  label-for="famount"
                >
                  <b-form-input
                    id="famount"
                    v-model="receiptLocal.famount"
                    autofocus
                    type="number"
                    placeholder=""
                    step="0.01"
                    @input="renderAmount"
                  />
                </b-form-group>
            </b-col>
        </b-row>
          
          <b-row>
            <!-- Amount -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Amount"
                rules="required"
              >
                <b-form-group
                  label="Amount"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    v-model="receiptLocal.amount"
                    autofocus
                    type="number"
                    :state="getValidationState(validationContext)"
                    placeholder=""
                    step="0.01"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Type -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="receiptLocal.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :clearable="false"
                    :reduce="val => val.value"
                    input-id="type"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <!-- Dayborn -->
            <b-col md="6">
              <b-form-group
                label="Dayborn"
                label-for="dayborn"
              >
                <v-select
                  v-model="receiptLocal.dayborn"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="daybornOptions"
                  :clearable="true"
                  :reduce="val => val.value"
                  input-id="dayborn"
                  :disabled="['welfare', 'special offering', 'thanksgiving', 'tithe', 'others'].includes(receiptLocal.type)"
                />
              </b-form-group>
            </b-col>
            <!-- Service -->
            <b-col md="6">
              <b-form-group
                label="Service"
                label-for="service"
              >
                <v-select
                  v-model="receiptLocal.service"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="serviceOptions"
                  :clearable="true"
                  :reduce="val => val.value"
                  input-id="service"
                  :disabled="['harvest', 'harvest pledge', 'welfare dues', 'special offering', 'tithe', 'others'].includes(receiptLocal.type)"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- getAllMembers -->
          <b-form-group
                label="Member"
                label-for="member"
              >
                <v-select
                  v-model="receiptLocal.member"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="getAllMembers.map(m=>({ member: m.fullName, memID: m.memID, contact1: m.contact1, memberID: m.fullName+' - '+m.memID }))"
                  label="memberID"
                  :close-on-select="true"
                  :clearable="true"
                  input-id="member"
                  :disabled="!['welfare dues', 'thanksgiving', 'harvest pledge', 'tithe'].includes(receiptLocal.type)"
                />
                <template #option="{ memberID }">
                  <!-- <b-avatar
                    size="sm"
                    :src="avatar"
                  /> -->
                  <!-- <span> {{ memID }}</span> -->
                  <span class="ml-50 align-middle"> {{ memberID }}</span>
                </template>

                <template #selected-option="{  memberID }">
                  <!-- <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  /> -->
                  <span class="ml-50 align-middle"> {{ memberID }}</span>
                </template>    
          </b-form-group>
          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-form-datepicker
              v-model="receiptLocal.date"
              placeholder="YYYY-MM-DD"
            ></b-form-datepicker>
          </b-form-group>

          <b-row>
            <!-- Payment -->
            <b-col md="6">
              <b-form-group
                label="Payment"
                label-for="payment"
              >
                <v-select
                  v-model="receiptLocal.payment"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentOptions"
                  :clearable="false"
                  :reduce="val => val.value"
                  input-id="payment"
                />
              </b-form-group>
            </b-col>
            <!-- Months -->
            <b-col md="6">
              <b-form-group
                  label="Month"
                  label-for="month"
                >
                  <b-form-input
                    id="month"
                    v-model="receiptLocal.months"
                    type="number"
                    placeholder=""
                    :disabled="![ 'welfare dues','tithe'].includes(receiptLocal.type)"
                  />

              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            label="Payment Details"
            label-for="paymentDetails"
          >
            <b-form-textarea
              id="paymentDetails"
              v-model="receiptLocal.paymentDetails"
              trim
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="submitDisabled"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton, BRow, BCol, BFormDatepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, toRefs } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import useMembersList from '../../../apps/membership/members-list/useMemberList'
import store from '@/store'
import useReceiptNewAddNew from './useReceiptNewAddNew'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewReceiptSidebarActive',
    event: 'update:is-add-new-receipt-sidebar-active',
  },
  props: {
    isAddNewReceiptSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    typeOptions:{
      type: Array,
      required: true,
    },
    currencies:{
      type: Array,
      required: false,
    },
    paymentOptions:{
      type: Array,
      required: true,
    },
    serviceOptions:{
      type: Array,
      required: true,
    },
    daybornOptions:{
      type: Array,
      required: true,
    },
    receiptData: {
      type: Object
    },
    clearReceiptData: {
      type: Function,
      required: true,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    
    const clearFormData = ref(null)
      
    const {
      receiptLocal,
      resetReceiptLocal,
      onSubmit, 
      
    } = useReceiptNewAddNew(toRefs(props), clearFormData, emit)

    const { getAllMembers } = useMembersList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetReceiptLocal, props.clearReceiptData)

    const renderAmount = () => {
      //
      receiptLocal.value.amount =  parseFloat(receiptLocal.value.currency.rate) * receiptLocal.value.famount
    }

    clearFormData.value = clearForm

    return {
      
      receiptLocal,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      getAllMembers,
      renderAmount
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
