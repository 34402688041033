<template>

  <div>

    <receipt-list-add-new
      :is-add-new-receipt-sidebar-active.sync="isAddNewReceiptSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :type-options="typeOptions"
      :payment-options="paymentOptions"
      :service-options="serviceOptions"
      :dayborn-options="daybornOptions"
      :clear-receipt-data="clearReceiptData"
      :receiptData="receiptData"
      :submitDisabled="submitDisabled"
      @add-receipt="addReceipt"
      @update-receipt="updateReceipt"
      :currencies="churchCurrencies"
    />

    <!-- Filters -->
    <receipts-list-filters
      :type-filter.sync="typeFilter"
      :dayborn-filter.sync="daybornFilter"
      :payment-filter.sync="paymentFilter"
      :service-filter.sync="serviceFilter"
      :status-filter.sync="statusFilter"
      :month-filter.sync="monthFilter"
      :type-options="typeOptions"
      :dayborn-options="daybornOptions"
      :payment-options="paymentOptions"
      :service-options="serviceOptions"
      :month-options="monthOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="10"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-datepicker
                v-model="fromDate"
                placeholder="Date From"
                class="mr-1"
                today-button
                reset-button
                close-button
              ></b-form-datepicker>
              
              <b-form-datepicker
                v-model="toDate"
                placeholder="Date To"
                class="mr-1"
                today-button
                reset-button
                close-button
              ></b-form-datepicker>
              
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="primary"
                tag="router-link"
                :to="{ name: 'apps-receipts-add' }"
                class="mr-2"
              >
                <span class="text-nowrap">Add Receipt</span>
              </b-button> -->
              <b-button
                variant="primary"
                class="mr-1"
                @click="isAddNewReceiptSidebarActive = true"
              >
                <span class="text-nowrap">Add Receipt</span>
              </b-button>
              
              <b-button
                variant="outline-primary"
                class="mr-1"
                @click="genReceiptReport"
              >
                <span class="text-nowrap">Export to *.Excel</span>
              </b-button>

              <b-button
                variant="outline-primary"
                :to="({ name: 'accounts-receipt-preview', 
                    params: {
                        id: '0',
                        action: 'preview', 
                        params: { 
                          sortBy: sortBy ? sortBy.value : 'id',
                          sortDesc: isSortDirDesc ? isSortDirDesc.value : false,
                          perPage: dataMeta ? dataMeta.of : 10,
                          page: 1,
                          q: searchQuery ? searchQuery : '', 
                          from: fromDate ? fromDate : '', 
                          to: toDate ? toDate : '', 
                          type: typeFilter ? typeFilter : null,
                          dayborn: daybornFilter ? daybornFilter : null,
                          payment: paymentFilter ? paymentFilter : null,
                          service: serviceFilter ? serviceFilter : null,
                          status: statusFilter ? statusFilter : null,
                          month: monthFilter ? monthFilter : null
                        } 
                    } 
                  })"
              >
                <span class="text-nowrap">Report Preview </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refReceiptListTable"
        class="position-relative"
        :items="getReceipts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <!-- Column: Id -->
      <template #cell(id)="data">
        <!-- <b-link
          :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
          class="font-weight-bold"
        > -->
          <!-- #{{ data.value }} -->
          {{ data.index+1 }}
        <!-- </b-link> -->
      </template>

      <!-- Column: Role -->
      <template #cell(type)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveTypeRoleIcon(data.item.type)"
              size="18"
              class="mr-50"
              :class="`text-primary`"
            />
            <span class="font-weight-bold align-text-top text-capitalize">{{ resolveTypeVariant(data.item.type) }} {{ ["tithe", "welfare dues", "harvest pledge"].includes(data.item.type) ? ': '+data.item._date.substring(3) : (data.item.date !== data.item.receiptDate && data.item.type == "offering") ? ': '+ new Date(data.item.date).toGMTString().substring(0,16) : '' }}</span>
          </div>
        </template>

      <template #cell(amount)="data">
        <p
          class="font-weight-bolder text-right mb-0 text-primary"
        >
           {{ userData.baseCurrency.sign + formatAmount(data.item.amount) }}
        </p>
      </template>

      <template #cell(service)="data">
        <span
          class="font-weight-bold text-"
        >
          {{ data.item.service ? data.item.service.substring(0,1).toUpperCase()+data.item.service.substring(1) : '' }}
        </span>
      </template>

      <!-- Column: memID -->
      <template #cell(memID)="data">
        <!-- <b-link
          :to="{ name: 'apps-receipts-view', params: { id: data.item.id }}"
          class="font-weight-bold text-secondary"
        > -->
          {{ data.item.memID ? '#'+data.item.memID  : ''}}
        <!-- </b-link> -->
      </template>

        <!-- Column: Contact -->
      <template #cell(member)="data">
        <b-link
           v-if="data.item._member !== ''"
          :href="`tel:${data.item.member.contact1.countryCode+data.item.member.contact1.tel}`"
          class="font-weight-bold"
          title="Call"
        >
          {{ data.item._member  }}
        </b-link>
      </template>
      

        <!-- Column: Role -->
        <template #cell(payment)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              :icon="resolveReceiptRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              
            /> -->
            <b-badge :variant="`${resolveReceiptPaymentVariant(data.item.payment)}`">{{ resolvePaymentVariant(data.item.payment) }}</b-badge>
            <!-- <span class="align-text-top text-capitalize">{{ data.item.role }}</span> -->
          </div>
        </template>

        <!-- Column: DOB -->
        <template #cell(_receiptDate)="data">
          <div class="text-dark">
            {{   data.item._receiptDate }}
          </div>
        </template>

        <!-- Column: Dayborn -->
        <template #cell(dayborn)="data">
          <div class="text-dark">
            {{ resolveDaybornVariant(data.item.dayborn) }}
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveReceiptStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'accounts-receipt-preview', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Preview</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editReceipt(data.item)" v-if="data.item.status == 'pending'">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="send(data.item)" v-if="data.item.status == 'pending'" v-b-modal.cancel-modal>
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Cancel</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('read', 'Analytics')" @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            
            <span v-else>
              <b-dropdown-item v-if="data.item.status == 'pending'" @click="confirmText(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </span>
            
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ (perPage == 'All') ? getReceipts.length : dataMeta.from +' to '+ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            <span class="text-muted"> &#8212;  {{ userData.baseCurrency.sign + totalReceiptAmount.toLocaleString() }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalReceipts+' - '+totalAmount"
              :per-page="(perPage == 'All') ? getReceipts.length : perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


    <!--  -->
    <b-modal
      id="cancel-modal"
      :title="`Cancel Receipt ${receipt ? ': '+receipt.type+' : '+receipt.amount : ''}`"
      ok-only
      ok-title="Close"
    > 
    <!-- Body -->
    <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Reason -->
            <validation-provider
                    #default="validationContext"
                    name="Reason"
                    rules="required"
                  >
              <b-form-group
                  label="Reason for cancelation"
                  label-for="reason"
                >
                  <b-form-textarea
                    id="reason"
                    v-model="receipt.reason"
                    trim
                    placeholder="Reason"
                    :state="getValidationState(validationContext)"
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-button type="submit">
              Submit
            </b-button>
          </b-form>
      </validation-observer>
      <!-- <b-card-text>
        <h5>Check First Paragraph</h5>
        Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
        Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
      </b-card-text> -->
      
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormInput, BFormTextarea, BFormGroup, BButton, BTable, BMedia, BAvatar, BLink, BModal, VBModal,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormInvalidFeedback, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ReceiptsListFilters from './ReceiptListFilters.vue'
import useReceiptList from './useReceiptList'
import receiptStoreModule from '../receiptStoreModule'
import memberStoreModule from '../../../apps/membership/memberStoreModule'
import smsStoreModule from '../../../apps/sms/smsStoreModule'
import ReceiptListAddNew from '../receipt-add-new/ReceiptListAddNew.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { formatAmount } from '@core/comp-functions/formatAmount'
import axios from '@/libs/axios'


export default {
  components: {
    ReceiptsListFilters,
    ReceiptListAddNew,

    BCard,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BModal, 
    VBModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    vSelect,
    BFormDatepicker
  },
  
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {

    const userData = getUserData()
    const churchCurrencies = ref([])

    const blankReceiptData = {
      id: '',
      type: '',
      amount: '',
      reason: ''
    }

    const receipt = ref(JSON.parse(JSON.stringify(blankReceiptData)))

    const send = (data) => {
      //
      receipt.value.id = data._id
      receipt.value.type = data.type
      receipt.value.amount = data.amount

    }

    const APP_SMS_STORE_MODULE_NAME = 'app-sms'
    const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'account-receipt'
    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'

    // Register module
      if (!store.hasModule(APP_SMS_STORE_MODULE_NAME)) store.registerModule(APP_SMS_STORE_MODULE_NAME, smsStoreModule)
    if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
        if (store.hasModule(APP_SMS_STORE_MODULE_NAME)) store.unregisterModule(APP_SMS_STORE_MODULE_NAME)
      if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
    })

    const receiptLocal = ref(JSON.parse(JSON.stringify(receipt)))
    const resetReceiptLocal = () => {
      receiptLocal.value = JSON.parse(JSON.stringify(receipt))
    }

    const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
    } = formValidation(resetReceiptLocal, receipt)


    axios.get(`/church/${userData.church}`).then(res => { 
      
      churchCurrencies.value = res.data.currencies; 

    })

    const confirmText = (data) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          delReceipt(data)
        }
      })
    }


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Approved', value: 'approved' },
      { label: 'Pending', value: 'pending' },
      { label: 'Cancelled', value: 'cancelled' },
    ]

    const paymentOptions = [
      { label: 'Cash', value: 'cash' },
      { label: 'Mobile Money', value: 'mobile_money' },
      { label: 'Cheque', value: 'cheque' },
    ]

    const daybornOptions = [
      { label: 'Sunday', value: 'sunday' },
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
    ]

    const serviceOptions = [
      { label: 'First', value: 'first' },
      { label: 'Second', value: 'second' },
      { label: 'Joint', value: 'joint' },
    ]

    const typeOptions = [
      { label: 'Offering', value: 'offering' },
      { label: 'Tithe', value: 'tithe' },
      { label: 'Welfare', value: 'welfare' },
      { label: 'Welfare Dues', value: 'welfare dues' },
      { label: 'Harvest', value: 'harvest' },
      { label: 'Harvest Pledge', value: 'harvest pledge' },
      { label: 'Special Offering', value: 'special offering' },
      { label: 'Thanksgiving', value: 'thanksgiving' },
      { label: 'Others', value: 'others' },
    ]

    const monthOptions = [
      { label: 'January', value: 'Jan' },
      { label: 'February', value: 'Feb' },
      { label: 'March', value: 'Mar' },
      { label: 'April', value: 'Apr' },
      { label: 'May', value: 'May' },
      { label: 'June', value: 'Jun' },
      { label: 'July', value: 'Jul' },
      { label: 'August', value: 'Aug' },
      { label: 'September', value: 'Sep' },
      { label: 'October', value: 'Oct' },
      { label: 'November', value: 'Nov' },
      { label: 'December', value: 'Dec' },
    ]

    const {
      getReceipts,
      fetchReceipts,
      fetchMembers,
      tableColumns,
      perPage,
      currentPage,
      totalReceipts,
      totalAmount,
      dataMeta,
      perPageOptions,
      searchQuery,
      fromDate,
      toDate,
      sortBy,
      isSortDirDesc,
      refReceiptListTable,
      refetchData,

      // UI
      resolveReceiptStatusVariant,
      resolvePaymentVariant,
      resolveReceiptPaymentVariant,
      resolveDaybornVariant,
      resolveTypeVariant,
      resolveTypeRoleIcon,

      //
      isAddNewReceiptSidebarActive,
      clearReceiptData,
      editReceipt,
      delReceipt,
      addReceipt,
      updateReceipt,
      receiptData,
      genReceiptReport,

      // Extra Filters
      typeFilter,
      daybornFilter,
      paymentFilter,
      serviceFilter,
      statusFilter,
      monthFilter,
      totalReceiptAmount,
      cancelReceipt,
      submitDisabled
    } = useReceiptList()

    fetchReceipts()

    fetchMembers()

    
    const onSubmit = () => {
      cancelReceipt(receipt.value)
      clearForm.value()
    }

    // 
    setTimeout(()=>{
      
      // edit request
      if (router.currentRoute.params.id) {
        //
        const data = getReceipts.value.find(rec => rec.id == router.currentRoute.params.id)
        editReceipt(data)
      }

      if (router.currentRoute.params.add) {
        //
        isAddNewReceiptSidebarActive.value = true
      }

      if (router.currentRoute.params.type && router.currentRoute.params.date && router.currentRoute.params.status) {
        //
        typeFilter.value = router.currentRoute.params.type
        statusFilter.value = router.currentRoute.params.status
        searchQuery.value = router.currentRoute.params.date
        }

      if (router.currentRoute.params.search) {
        searchQuery.value = router.currentRoute.params.search
        }
        
      }, 500)

    
  
    return {

      // Sidebar
      isAddNewReceiptSidebarActive,

      getReceipts,
      fetchReceipts,
      tableColumns,
      perPage,
      currentPage,
      totalReceipts,
      totalAmount,
      dataMeta,
      perPageOptions,
      searchQuery,
      fromDate,
      toDate,
      sortBy,
      isSortDirDesc,
      refReceiptListTable,
      refetchData,
      genReceiptReport,

      // Filter
      avatarText,

      // UI
      resolveReceiptStatusVariant,
      resolvePaymentVariant,
      resolveReceiptPaymentVariant,
      resolveDaybornVariant,
      resolveTypeVariant,
      resolveTypeRoleIcon,

      typeOptions,
      paymentOptions,
      daybornOptions,
      serviceOptions,
      roleOptions,
      planOptions,
      statusOptions,
      monthOptions,

      //
      clearReceiptData,
      editReceipt,
      delReceipt,
      addReceipt,
      updateReceipt,
      receiptData,
      receipt,
      send,
      
      //
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,

      // Extra Filters
      typeFilter,
      daybornFilter,
      paymentFilter,
      serviceFilter,
      statusFilter,
      monthFilter,
      totalReceiptAmount,
      confirmText,
      onSubmit,
      formatAmount,
      userData,
      churchCurrencies,
      submitDisabled
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
